// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auction-info-js": () => import("./../../../src/pages/auction-info.js" /* webpackChunkName: "component---src-pages-auction-info-js" */),
  "component---src-pages-auction-js": () => import("./../../../src/pages/auction.js" /* webpackChunkName: "component---src-pages-auction-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-fair-dinkum-sheds-js": () => import("./../../../src/pages/fair-dinkum-sheds.js" /* webpackChunkName: "component---src-pages-fair-dinkum-sheds-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-upcoming-auctions-js": () => import("./../../../src/pages/upcoming-auctions.js" /* webpackChunkName: "component---src-pages-upcoming-auctions-js" */)
}

